/* ----- imports -------------------------------------------------------- */
import ready from './utils/ready';
import {
  $,
  $$
} from "./utils/selector";

/* ----- main -------------------------------------------------------- */

ready().then(() => {
  // Toggle menu class is-active on both the navbar-burger and the targeted navbar-menu
  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call($$(document, '.navbar-burger'), 0);

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {

    // Add a click event on each of them
    $navbarBurgers.forEach(function (el) {
      el.addEventListener('click', function () {

        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');
      });
    });
  }

  // Getting all the modals, close and trigger buttons
  var rootEl = document.documentElement;
  var $modals = $$(document, '.modal');
  var $modalButtons = $$(document, '.modal-button');
  var $modalCloses = $$(document, '.modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button');

  if ($modalButtons.length > 0) {
    $modalButtons.forEach(function (el) {
      el.addEventListener('click', function () {
        var target = el.dataset.target;
        if (null != target) {
          openModal(target);
        }
      });
    });
  }

  if ($modalCloses.length > 0) {
    $modalCloses.forEach(function (el) {
      el.addEventListener('click', function () {
        closeModals();
      });
    });
  }

  function openModal(target) {
    var $target = document.getElementById(target);
    rootEl.classList.add('is-clipped');
    $target.classList.add('is-active');
  }

  function closeModals() {
    rootEl.classList.remove('is-clipped');
    $modals.forEach(function (el) {
      el.classList.remove('is-active');
    });
  }

  // Make non-Hoverable Dropdowns toggable
  var dropdowns = document.querySelectorAll('.dropdown:not(.is-hoverable)');
  if (dropdowns.length > 0) {
    dropdowns.forEach(dropdown => {
      dropdown.addEventListener('click', event => {
        event.stopPropagation();
        // collapse other dropdowns
        dropdowns.forEach(other => {
          if (!other.isSameNode(dropdown)) {
            other.classList.remove('is-active');
          }
        });
        // expand clicked dropdown
        dropdown.classList.toggle('is-active');
      });
    });

    document.addEventListener('click', event => {
      dropdowns.forEach(dropdown => {
        // collapse any expanded dropdown
        dropdown.classList.remove('is-active');
      });
    });
  }

  // Getting all tooltips ready
  var ttHelper = function () {
    var tooltipButtons = document.querySelectorAll('a.tooltip-button');

    if (0 < tooltipButtons.length) {
      tooltipButtons.forEach(el => {
        el.addEventListener('click', function () {
          var target = el.dataset.target;
          if (null != target) {
            var $target = document.getElementById(target);
            $target.classList.toggle('is-hidden');
          }
        });
      });
    }

  }();
});
