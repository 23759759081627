const $ = (base, selector) => {
  return base.querySelector(selector);
};

const $$ = (base, selector) => {
  return [].slice.call(base.querySelectorAll(selector));
};

export {
  $,
  $$
};
